import styled from 'styled-components'
import Button from '@veneer/core/dist/scripts/button'

export const SuggestedAddressModal = styled.div`
  text-align: left;
`

export const AddressContainer = styled.div`
  && {
    padding-bottom: 24px;
  }
`

export const SubHeader = styled.p`
  && {
    font-weight: 900;
  }
`

export const AddressOption = styled.div`
  && {
    margin: 15px 0;
    display: flex;
  }
`

export const ConfirmButton = styled(Button)`
  && {
    display: flex;
    margin: auto;
  }
`
