import { useEffect } from 'react'
import { useDispatch } from './useDispatch'
import { useShippingFormState } from './useShippingFormState'
import { handleFetchShippingAddressesAction } from '../actions'
import { ShippingFormData } from '../types'

export const useShippingAddresses = (): Array<ShippingFormData> => {
  const {
    shippingAddresses,
    shippingAddressesError,
    shippingAddressesLoading
  } = useShippingFormState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      shippingAddresses === undefined &&
      !shippingAddressesLoading &&
      !shippingAddressesError
    ) {
      ;(async () => {
        await dispatch(handleFetchShippingAddressesAction())
      })()
    }
  }, [
    shippingAddresses,
    shippingAddressesLoading,
    shippingAddressesError,
    dispatch
  ])

  return shippingAddresses
}
