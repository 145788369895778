import React from 'react'
import { ShippingForm } from '../src'
import projectNames from '../src/configs/projectNames'
import { ShippingFormProps } from '../src/components/ShippingForm/ShippingForm'
import { MfeSection } from './styles'
import { ThemeProvider as VeneerThemeProvider } from '@veneer/theme'
import { ThemeSettings } from '../src/types'

export default function Root(props: {
  properties: ShippingFormProps & { themeSettings: ThemeSettings }
}) {
  return (
    <MfeSection
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
      $enableShippingAutofill={props.properties?.enableShippingAutofill}
    >
      <VeneerThemeProvider
        customSemantics={props.properties.themeSettings.customSemantics}
        mode={props.properties.themeSettings.mode}
        shape={props.properties.themeSettings.shape}
        density={props.properties.themeSettings.density}
      >
        <ShippingForm {...props.properties} />
      </VeneerThemeProvider>
    </MfeSection>
  )
}
