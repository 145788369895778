import { ShippingFormAction, ValidateFieldOptions } from '../types'
import { ShippingFormActionType } from '../constants/shippingFormAction'

export const validateFieldAndCacheShippingDataAction = ({
  field,
  value,
  options = {}
}: {
  field: string
  value: string
  options?: ValidateFieldOptions
}): ShippingFormAction => ({
  type: ShippingFormActionType.VALIDATE_FIELD_AND_CACHE_SHIPPING_DATA,
  field,
  value,
  options
})
