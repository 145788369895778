import styled, { css } from 'styled-components'

export const ShippingFormContainer = styled.div<{
  $enableShippingAutofill: boolean
}>`
  margin-top: 16px;

  ${({ $enableShippingAutofill }) =>
    $enableShippingAutofill &&
    css`
      height: calc(100% - 16px);
    `}
`
