import { ShippingFormData } from '../../types'

export const shippingDataFixture = (
  options: Partial<ShippingFormData> = { id: '123' }
): ShippingFormData => {
  const empty = options.id === undefined
  const {
    tenantClassification = 'consumer',
    supportMultiShipping = false,
    id,
    street1 = empty ? '' : '16399 W Bernardo Dr',
    street2 = '',
    city = empty ? '' : 'San Diego',
    state = empty ? '' : 'CA',
    fullState = empty ? '' : 'California',
    zipCode = empty ? '' : '92127',
    countryCode = empty ? '' : 'US',
    firstName = empty ? '' : 'John',
    lastName = empty ? '' : 'Doe',
    fullName = empty ? '' : 'John Doe',
    company = empty ? '' : 'HP Inc.',
    phoneNumber1 = empty ? '' : '1234567891',
    optedIn = false,
    isDefaultAddress = false,
    allowPoBox = true
  } = options
  return {
    tenantClassification,
    supportMultiShipping,
    id,
    street1,
    street2,
    city,
    state,
    fullState,
    zipCode,
    countryCode,
    firstName,
    lastName,
    fullName,
    company,
    phoneNumber1,
    optedIn,
    isDefaultAddress,
    allowPoBox
  }
}
