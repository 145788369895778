import { ShippingFormAction } from '../types'
import { ShippingFormActionType } from '../constants/shippingFormAction'

export const setAssetsProviderAction = ({
  language,
  country
}: {
  language: string
  country: string
}): ShippingFormAction => ({
  type: ShippingFormActionType.SET_ASSETS_PROVIDER,
  language,
  country
})
