import { useEffect } from 'react'
import { useDispatch } from './useDispatch'
import { useShippingFormState } from './useShippingFormState'
import { handleFetchSettingsAction } from '../actions'
import { SettingsData } from '../types'

export const useSettingsData = (): SettingsData => {
  const { settingsData, settingsDataError, settingsDataLoading } =
    useShippingFormState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      settingsData === undefined &&
      !settingsDataLoading &&
      !settingsDataError
    ) {
      ;(async () => {
        await dispatch(handleFetchSettingsAction())
      })()
    }
  }, [settingsData, settingsDataLoading, settingsDataError, dispatch])

  return settingsData
}
