import styled, { css } from 'styled-components'
import { isMobile } from '../../lib/screenHelpers'

export const GenericErrorHandlerContent = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  width: 95%;
  margin: auto;
`

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 272px;

  svg {
    margin-bottom: 24px;
  }
`

export const DebugSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`

export const ButtonSection = styled.span`
  display: flex;
  margin-top: 30px;
  align-content: center;
  justify-content: space-between;

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      flex-direction: column-reverse;
      align-items: center;
    `}

  button {
    margin-right: 24px;

    ${({ theme: { containerSize } }) =>
      isMobile(containerSize) &&
      css`
        margin-bottom: 16px;
        margin-right: 0;
        width: 100%;
      `}

    &:last-child {
      margin-right: 0;
    }
  }
`

export const UnsupportedPostalCodeCloseButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
