import { createContext } from 'react'
import { ShippingFormAction, ShippingFormState } from '../types'
import { AsyncDispatch } from '../hooks/useAsyncReducer'

export type ShippingFormContextType = {
  shippingFormState?: ShippingFormState
  dispatch?: AsyncDispatch<ShippingFormAction, ShippingFormState>
}

export const ShippingFormContext = createContext<ShippingFormContextType>({})
