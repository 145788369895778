import styled from 'styled-components'
import Button from '@veneer/core/dist/scripts/button'

export const UnsupportedPaperAddressContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const UnsupportedPaperAddressBody = styled.p`
  margin-bottom: 72px;
`

export const UnsupportedPaperAddressCloseButton = styled(Button)`
  display: flex;
  align-self: flex-end;
`
