import {
  DispatchFunction,
  OnSaveError,
  ShippingFormAction,
  ShippingFormData,
  ShippingFormDataField,
  ShippingFormDispatchHandler,
  ShippingFormState
} from '../types'
import { FormErrorCodes } from '../constants/shippingForm'
import { ShippingFormActionType } from '../constants/shippingFormAction'
import {
  associateAddressWithPrinterAction,
  associateAddressWithPrinterSuccessAction,
  saveFormErrorCodeAction
} from '.'

const openSuggestedAddressModalAction = (
  data: ShippingFormData
): ShippingFormAction => ({
  type: ShippingFormActionType.OPEN_SUGGESTED_ADDRESS_MODAL,
  data
})

const openUnsupportedPostalCodeModalAction = (): ShippingFormAction => ({
  type: ShippingFormActionType.OPEN_UNSUPPORTED_POSTAL_CODE_MODAL
})

const openUnsupportedPaperAddressModalAction = (): ShippingFormAction => ({
  type: ShippingFormActionType.OPEN_UNSUPPORTED_PAPER_ADDRESS_MODAL
})

const clearErrorFieldsAction = (): ShippingFormAction => ({
  type: ShippingFormActionType.CLEAR_ERROR_FIELDS
})

const enableErrorsAction = (): ShippingFormAction => ({
  type: ShippingFormActionType.ENABLE_ERRORS
})

const setValidatedAddressAction = (): ShippingFormAction => ({
  type: ShippingFormActionType.SET_VALIDATED_ADDRESS
})

const validateAllFieldsAction = (): ShippingFormAction => ({
  type: ShippingFormActionType.VALIDATE_ALL_FIELDS
})

const validateAllFieldsFailedAction = (
  error: Record<string, unknown>
): ShippingFormAction => ({
  type: ShippingFormActionType.VALIDATE_ALL_FIELDS_FAILED,
  error
})

const cacheShippingDataAction = (
  data: ShippingFormData
): ShippingFormAction => ({
  type: ShippingFormActionType.CACHE_SHIPPING_DATA,
  data
})

export const handleSaveOrUpdateShippingDataAction =
  (): ShippingFormDispatchHandler =>
  async (dispatch: DispatchFunction, getState: () => ShippingFormState) => {
    const { onSave, validatedAddress, shippingData, fields } = getState()
    let addressId

    try {
      const saveOptions = { ignoreValidationErrors: 'false' }

      await dispatch(enableErrorsAction())

      if (validatedAddress === shippingData) {
        await dispatch(clearErrorFieldsAction())
      } else {
        await dispatch(validateAllFieldsAction())
      }

      const getValidationErrors = async () => {
        const { enableErrors, errorFields } = getState()

        return enableErrors && errorFields.size > 0
      }
      const hasValidationErrors = await getValidationErrors()

      if (!hasValidationErrors) {
        let associationFailed = false

        const setValidatedAddress = async () => {
          const { suggestedAddress } = getState()

          const compareAddresses = (add1, add2) => {
            if (add1 && add2) {
              const differentKeys = Object.keys(add1).filter(
                (field) => add1[field] !== add2[field]
              )
              const sameLength = add1.length === add2.length
              return !differentKeys.length && sameLength
            }

            return false
          }

          if (
            compareAddresses(validatedAddress, shippingData) ||
            compareAddresses(suggestedAddress, shippingData)
          ) {
            saveOptions.ignoreValidationErrors = 'true'
          } else {
            await dispatch(setValidatedAddressAction())
          }
        }
        await setValidatedAddress()

        const updateServerAndRefreshCache = async () => {
          const { shippingService, shippingData } = getState()

          if (shippingData && shippingData.id) {
            const { data } = await shippingService.updateAddress({
              id: shippingData.id,
              data: shippingData,
              params: saveOptions
            })
            addressId = data?.id

            await dispatch(cacheShippingDataAction(data))
          } else {
            const { data } = await shippingService.saveAddress({
              data: shippingData,
              params: saveOptions
            })
            addressId = data?.id
            await dispatch(cacheShippingDataAction(data))

            const associateAddressWithPrinter = async () => {
              const { cloudId } = getState()
              try {
                if (data && data.supportMultiShipping) {
                  await dispatch(associateAddressWithPrinterAction())

                  if (cloudId && addressId) {
                    const { data } =
                      await shippingService.associateAddressWithPrinter({
                        id: addressId,
                        cloud_id: cloudId
                      })

                    await dispatch(
                      associateAddressWithPrinterSuccessAction(data)
                    )
                  }
                }
              } catch (error) {
                associationFailed = true
                await dispatch(
                  saveFormErrorCodeAction(FormErrorCodes.ASSOCIATION_FAILED)
                )
              }
            }
            await associateAddressWithPrinter()
          }
        }
        await updateServerAndRefreshCache()

        if (!associationFailed) {
          onSave(null, { addressId: addressId })
        }
      }
    } catch (error) {
      await dispatch(validateAllFieldsFailedAction(error))

      const status = error?.response?.status
      const code = error?.response?.data?.error?.code
      const suggestedAddress = error?.response?.data?.error?.suggestedAddress
      const warningFields = (keys: ShippingFormDataField[]): Set<string> =>
        keys.reduce((result, key) => {
          if (fields.has(key)) {
            result.add(key)
          }
          return result
        }, new Set<string>())

      switch (code) {
        case FormErrorCodes.ADDRESS_NOT_FOUND:
          await dispatch(
            saveFormErrorCodeAction(
              code,
              warningFields([
                ShippingFormDataField.street1,
                ShippingFormDataField.state,
                ShippingFormDataField.city,
                ShippingFormDataField.zipCode
              ])
            )
          )
          break
        case FormErrorCodes.SUGGESTED_ADDRESS_FOUND:
          await dispatch(openSuggestedAddressModalAction(suggestedAddress))
          break
        case FormErrorCodes.UNSUPPORTED_ADDRESS_FOR_INSTANT_PAPER:
          await dispatch(
            saveFormErrorCodeAction(
              code,
              warningFields([
                ShippingFormDataField.street1,
                ShippingFormDataField.street2,
                ShippingFormDataField.zipCode
              ])
            )
          )
          await dispatch(openUnsupportedPaperAddressModalAction())
          break
        case FormErrorCodes.UNSUPPORTED_POSTAL_CODE:
          await dispatch(openUnsupportedPostalCodeModalAction())
          break
        default:
          if (status === 401) {
            if (onSave) {
              onSave(OnSaveError.expiredToken, {})
            }
          } else if (status === 403) {
            if (onSave) {
              onSave(OnSaveError.expiredCriticalScope, {})
            }
          } else {
            await dispatch(
              saveFormErrorCodeAction(FormErrorCodes.GENERIC_ERROR)
            )
          }
          break
      }
    }
  }
