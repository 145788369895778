import React from 'react'
import Button from '@veneer/core/dist/scripts/button'
import {
  useGetText,
  useOnCancel,
  useDispatch,
  useFetchErrors,
  useShippingFormState
} from '../../hooks'
import { retryFailedAction } from '../../actions'
import WarningIcon from '@veneer/core/dist/scripts/icons/icon_warning_alt'
import * as Styled from './styles'

export const GenericErrorHandler = (): JSX.Element => {
  const getText = useGetText()
  const onCancel = useOnCancel()
  const fetchErrors = useFetchErrors()
  const dispatch = useDispatch()
  const onRetry = () => dispatch(retryFailedAction(fetchErrors))

  const {
    enableDebugging,
    shippingAddressesError,
    shippingDataError,
    settingsDataError
  } = useShippingFormState()

  if (fetchErrors.length === 0) {
    return null
  }

  const renderCancelButton = () => {
    if (onCancel) {
      return (
        <Button
          appearance="secondary"
          data-testid="cancel-button"
          onClick={onCancel}
        >
          {getText('shipping_form.generic_error_handler.cancel')}
        </Button>
      )
    }

    return <div />
  }

  const renderErrors = () => {
    if (enableDebugging) {
      return (
        <Styled.DebugSection>
          {shippingAddressesError && (
            <div>
              ShippingAddressesError:{' '}
              {shippingAddressesError.message ||
                JSON.stringify(shippingAddressesError.response)}
            </div>
          )}
          {shippingDataError && (
            <div>
              ShippingDataError:{' '}
              {shippingDataError.message ||
                JSON.stringify(shippingDataError.response)}
            </div>
          )}
          {settingsDataError && (
            <div>
              SettingsDataError:{' '}
              {settingsDataError.message ||
                JSON.stringify(settingsDataError.response)}
            </div>
          )}
        </Styled.DebugSection>
      )
    }
  }

  return (
    <Styled.GenericErrorHandlerContent>
      <Styled.ContentSection>
        <h4 className="shipping-title">
          {getText('shipping_form.generic_error_handler.title')}
        </h4>
        <WarningIcon size={48} color="darkOrange6" />
        {getText('shipping_form.generic_error_handler.something_went_wrong')}
        {renderErrors()}
      </Styled.ContentSection>
      <Styled.ButtonSection>
        {renderCancelButton()}
        <Button
          appearance="primary"
          data-testid="retry-button"
          onClick={onRetry}
        >
          {getText('shipping_form.generic_error_handler.try_again')}
        </Button>
      </Styled.ButtonSection>
    </Styled.GenericErrorHandlerContent>
  )
}
