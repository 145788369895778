import React from 'react'
import RadioButton from '@veneer/core/dist/scripts/radio_button'
import IconCheckCircle from '@veneer/core/dist/scripts/icons/icon_checkmark_circle'
import { useGetText } from '../../../hooks'
import { ShippingFormData } from '../../../types'
import * as Styled from './styles'

export const AddressCard = ({
  address,
  selectedAddressId,
  onSelectAddress,
  defaultAddressId,
  isMobile
}: AddressCardProps): JSX.Element => {
  const getText = useGetText()
  const {
    id,
    fullName,
    company,
    street1,
    street2,
    city,
    state,
    zipCode,
    countryCode
  }: ShippingFormData = address

  const isDefaultAddress = id === defaultAddressId
  const isSelectAddress = id === selectedAddressId

  const defaultAddress = () => (
    <Styled.DefaultAddressContent>
      <IconCheckCircle size={24} color="hunterGreen7" filled />
      <Styled.DefaultAddress>
        {getText('shipping_form.select_address.default_address')}
      </Styled.DefaultAddress>
    </Styled.DefaultAddressContent>
  )

  const companyAndStreet1 = () => {
    const companyLine = <Styled.Company>{company}</Styled.Company>
    const street1Line = <Styled.Street1>{street1}</Styled.Street1>

    if (!isDefaultAddress || isMobile) {
      return (
        <>
          {companyLine}
          {street1Line}
        </>
      )
    }

    return (
      <>
        <Styled.WithDefaultAddress>
          {company ? companyLine : street1Line}
          {defaultAddress()}
        </Styled.WithDefaultAddress>
        {company ? street1Line : null}
      </>
    )
  }

  return (
    <Styled.AddressCardContainer>
      <Styled.AddressCard>
        <Styled.AddressIcon>
          <RadioButton onChange={onSelectAddress} checked={isSelectAddress} />
        </Styled.AddressIcon>
        <Styled.AddressContent>
          <Styled.AddressInfo>
            <Styled.Name>{fullName}</Styled.Name>
            {companyAndStreet1()}
            <p>{street2}</p>
            <p>{`${city}, ${state} ${zipCode} ${countryCode}`}</p>
          </Styled.AddressInfo>
        </Styled.AddressContent>
      </Styled.AddressCard>
      {isDefaultAddress && isMobile && defaultAddress()}
    </Styled.AddressCardContainer>
  )
}

type AddressCardProps = {
  address: ShippingFormData
  selectedAddressId: string
  onSelectAddress: () => void
  defaultAddressId: string
  isMobile: boolean
}
