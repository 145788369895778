import { FieldErrorCodes } from '../constants/shippingForm'
import { ShippingFormAction } from '../types'
import { ShippingFormActionType } from '../constants/shippingFormAction'

export const saveFieldErrorCodeAction = (
  fieldErrorCode: FieldErrorCodes
): ShippingFormAction => ({
  type: ShippingFormActionType.SAVE_FIELD_ERROR_CODE,
  fieldErrorCode
})
