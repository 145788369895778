import { useMemo } from 'react'
import { SelectOption } from '@veneer/core/dist/scripts/select'
import AssetsProviderFactory from '../assets/AssetsProviderFactory'
import { useShippingData } from './useShippingData'
import { useAssetsProvider } from './useAssetsProvider'
import { setFallbackLocale } from '../lib/localeHelpers'

export const useShippingCountryOptions = (): {
  countryName: string
  stateOptions: SelectOption[]
} => {
  const langCode = useAssetsProvider().getLanguage()
  const { countryCode } = useShippingData()
  return useMemo(() => {
    const { language, country } = setFallbackLocale(langCode, countryCode)
    const assetsProvider = AssetsProviderFactory.create(language, country)
    return {
      countryName: assetsProvider.getText('shipping_form.fields.country'),
      stateOptions: JSON.parse(
        assetsProvider.getText('state_dropdown.state_options')
      ).map((option: { [value: string]: unknown }) => {
        const [value, label] = Object.entries(option)[0]
        return { value, label }
      })
    }
  }, [langCode, countryCode])
}
