import React from 'react'
import Button from '@veneer/core/dist/scripts/button'
import Modal from '@veneer/core/dist/scripts/modal'
import {
  useShippingFormState,
  useGetText,
  useDispatch,
  useShippingData
} from '../../hooks'
import { closeUnsupportedPostalCodeModalAction } from '../../actions'
import * as Styled from './styles'

export const UnsupportedPostalCodeModal = (): JSX.Element => {
  const getText = useGetText()
  const dispatch = useDispatch()
  const shippingData = useShippingData()
  const { showUnsupportedPostalCodeModal } = useShippingFormState()

  const onClose = async () =>
    await dispatch(closeUnsupportedPostalCodeModalAction())

  if (!showUnsupportedPostalCodeModal) {
    return null
  }

  return (
    <Modal
      show={showUnsupportedPostalCodeModal}
      title={getText('shipping_form.unsupported_postal_code.title')}
      data-testid="unsupported-postal-code-modal"
      onClose={() => onClose()}
      closeButton
      id="title"
      closeOnBlur={false}
    >
      <Styled.UnsupportedPostalCodeContent>
        <Styled.UnsupportedPostalCodeMessage>
          <p
            dangerouslySetInnerHTML={{
              __html: getText(
                'shipping_form.unsupported_postal_code.message.part_1_html',
                { postalCode: shippingData.zipCode }
              )
            }}
          />
          <p>
            {getText('shipping_form.unsupported_postal_code.message.part_2')}
          </p>
          <p>
            {getText('shipping_form.unsupported_postal_code.message.sign_off')}
            <br />
            <Styled.UnsupportedPostalCodeSignature>
              {getText(
                'shipping_form.unsupported_postal_code.message.signature'
              )}
            </Styled.UnsupportedPostalCodeSignature>
          </p>

          <Styled.UnsupportedPostalCodeCloseButtonContainer>
            <Button
              data-testid="unsupported-postal-code-modal-go-back-button"
              appearance="secondary"
              onClick={() => onClose()}
            >
              {getText('shipping_form.unsupported_postal_code.message.go_back')}
            </Button>
          </Styled.UnsupportedPostalCodeCloseButtonContainer>
        </Styled.UnsupportedPostalCodeMessage>
      </Styled.UnsupportedPostalCodeContent>
    </Modal>
  )
}
