import { SettingsData } from '../../types'

export const settingsDataFixture = ({
  enableStreetCharacterLimitErrors = true,
  enableSmbPoBoxExclusion = true,
  enableTextMessageOptin = true
}: Partial<SettingsData> = {}): SettingsData => ({
  enableStreetCharacterLimitErrors,
  enableSmbPoBoxExclusion,
  enableTextMessageOptin
})
