import React from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import { closeUnsupportedPaperAddressModalAction } from '../../actions'
import { useDispatch, useGetText, useShippingFormState } from '../../hooks'
import * as Styled from './styles'

export const UnsupportedPaperAddressModal = (): JSX.Element => {
  const getText = useGetText()
  const dispatch = useDispatch()
  const onClose = async () =>
    await dispatch(closeUnsupportedPaperAddressModalAction())
  const { showUnsupportedPaperAddressModal } = useShippingFormState()
  const supportPhoneNumber = getText(
    'shipping_form.unsupported_paper_address.support_phone'
  )

  if (!showUnsupportedPaperAddressModal) {
    return null
  }

  return (
    <Modal
      show={showUnsupportedPaperAddressModal}
      title={getText('shipping_form.unsupported_paper_address.title')}
      data-testid="unsupported-paper-address-modal"
      onClose={() => onClose()}
      id="title"
    >
      <Styled.UnsupportedPaperAddressContent>
        <Styled.UnsupportedPaperAddressBody>
          {getText('shipping_form.unsupported_paper_address.body', {
            supportPhoneNumber
          })}
        </Styled.UnsupportedPaperAddressBody>
        <Styled.UnsupportedPaperAddressCloseButton onClick={onClose}>
          {getText('shipping_form.unsupported_paper_address.close')}
        </Styled.UnsupportedPaperAddressCloseButton>
      </Styled.UnsupportedPaperAddressContent>
    </Modal>
  )
}
