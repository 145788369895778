import { FormErrorCodes } from '../constants/shippingForm'
import { ShippingFormAction } from '../types'
import { ShippingFormActionType } from '../constants/shippingFormAction'

export const saveFormErrorCodeAction = (
  formErrorCode: FormErrorCodes,
  warningFields: Set<string> = new Set()
): ShippingFormAction => ({
  type: ShippingFormActionType.SAVE_FORM_ERROR_CODE,
  formErrorCode,
  warningFields
})
