import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  div[role=presentation] {
    z-index: 1600 !important;
  }

  ul[role=listbox] {
    z-index: 1700 !important;
  }

  input {
    &:disabled {
      -webkit-text-fill-color: $color-gray-4;
      opacity: 1;
    }
  }

`
