import React from 'react'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { useGetText } from '../../hooks'
import * as Styled from './styles'

export function Spinner(): JSX.Element {
  const getText = useGetText()

  return (
    <Styled.Loading data-testid="spinner">
      <ProgressIndicator />
      <span>{getText('shipping_form.loading')}</span>
    </Styled.Loading>
  )
}
