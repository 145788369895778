import { useEffect } from 'react'
import { useDispatch } from './useDispatch'
import { useShippingFormState } from './useShippingFormState'
import { handleFetchShippingAddressAction } from '../actions'
import { ShippingFormData } from '../types'

export const useShippingData = (): ShippingFormData => {
  const { shippingData, shippingDataError, shippingDataLoading } =
    useShippingFormState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      shippingData === undefined &&
      !shippingDataLoading &&
      !shippingDataError
    ) {
      ;(async () => {
        await dispatch(handleFetchShippingAddressAction())
      })()
    }
  }, [shippingData, shippingDataLoading, shippingDataError, dispatch])

  return shippingData
}
