export enum FieldErrorCodes {
  STREET1_CHARACTER_LIMIT = 'street1_character_limit',
  STREET2_CHARACTER_LIMIT = 'street2_character_limit',
  PO_BOX_NOT_ALLOWED = 'po_box_not_allowed'
}

export enum FormErrorCodes {
  ADDRESS_NOT_FOUND = 'address_not_found',
  SUGGESTED_ADDRESS_FOUND = 'suggested_address_found',
  GENERIC_ERROR = 'generic_error',
  UNSUPPORTED_POSTAL_CODE = 'unsupported_postal_code',
  UNSUPPORTED_ADDRESS_FOR_INSTANT_PAPER = 'unsupported_address_for_instant_paper',
  ASSOCIATION_FAILED = 'association_failed'
}
