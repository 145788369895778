import React, { useState } from 'react'
import { useShippingFormState, useShippingData } from '../../hooks'
import { SelectAddress } from '../SelectAddress'
import { ShippingContent } from '../ShippingContent'
import { Spinner } from '../Spinner'

export const ShippingView = (): JSX.Element => {
  const shippingData = useShippingData()
  const { shippingDataLoading, disableListView, hasExistingAddress } =
    useShippingFormState()
  const [newAddressView, setNewAddressView] = useState(false)

  if (shippingDataLoading) {
    return <Spinner />
  }

  if (shippingData === undefined) {
    return null
  }

  const { supportMultiShipping } = shippingData

  if (
    !newAddressView &&
    !disableListView &&
    supportMultiShipping &&
    hasExistingAddress
  ) {
    return <SelectAddress setNewAddressView={setNewAddressView} />
  }

  return <ShippingContent newAddressView={newAddressView} />
}
