import styled, { css } from 'styled-components'

export const MfeSection = styled.div<{
  $enableShippingAutofill: boolean
}>`
  ${({ $enableShippingAutofill }) =>
    $enableShippingAutofill &&
    css`
      height: 100%;
    `}
`
