import React, { FC, MutableRefObject, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { WindowSize, ContainerSize } from '../../types'

const { MOBILE_MAX, TABLET_PORTRAIT_MAX, TABLET_LANDSCAPE_MAX } = WindowSize
const { MOBILE, TABLET_PORTRAIT, TABLET_LANDSCAPE, DESKTOP } = ContainerSize

export const ContainerSizeProvider: FC<{
  containerRef: MutableRefObject<HTMLElement>
}> = ({ containerRef, children }): JSX.Element => {
  const [containerSize, setContainerSize] = useState(DESKTOP)

  useEffect(() => {
    if (containerRef) {
      const recalculateContainerSize = () => {
        const { clientWidth } = containerRef.current

        if (clientWidth < MOBILE_MAX) {
          setContainerSize(MOBILE)
        } else if (clientWidth <= TABLET_PORTRAIT_MAX) {
          //May need fixing
          setContainerSize(TABLET_PORTRAIT)
        } else if (clientWidth <= TABLET_LANDSCAPE_MAX) {
          //May need fixing
          setContainerSize(TABLET_LANDSCAPE)
        } else {
          setContainerSize(DESKTOP)
        }
      }

      recalculateContainerSize()
      window.addEventListener('resize', recalculateContainerSize)

      return () => {
        window.removeEventListener('resize', recalculateContainerSize)
      }
    }
  }, [containerRef])

  return (
    <ThemeProvider theme={{ containerSize }}>
      <>{children}</>
    </ThemeProvider>
  )
}
