import primitives from '@veneer/primitives'

const { breakpoint0, breakpoint2, breakpoint4, breakpoint6 } = primitives.layout

export enum WindowSize {
  MOBILE_MAX = parseInt(`${breakpoint0}px`, 10),
  TABLET_PORTRAIT_MAX = parseInt(`${breakpoint2}px`, 10),
  TABLET_LANDSCAPE_MAX = parseInt(`${breakpoint4}px`, 10),
  LAPTOP_MAX = parseInt(`${breakpoint6}px`, 10)
}
