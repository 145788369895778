import { Dispatchable, DispatchFunction, ShippingFormAction } from '../types'
import { ShippingFormActionType } from '../constants/shippingFormAction'

const clearFailedActions = (): ShippingFormAction => ({
  type: ShippingFormActionType.CLEAR_FAILED_ACTIONS
})

// function that returns a shiping form action or shipping form dispatch handler
export const retryFailedAction =
  (failedActions: Dispatchable[]) =>
  async (dispatch: DispatchFunction): Promise<void> => {
    await dispatch(clearFailedActions())
    failedActions.forEach((action) => {
      dispatch(action)
    })
  }
