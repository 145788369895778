import React, { useState } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import RadioButton from '@veneer/core/dist/scripts/radio_button'
import { ShippingFormData } from '../../types'
import { useShippingFormState, useGetText, useDispatch } from '../../hooks'
import {
  handleShipToAddressAction,
  saveSelectedAddressAction
} from '../../actions'
import * as Styled from './styles'

const renderAddress = (props: ShippingFormData) => {
  const { street1, street2, city, state, zipCode } = props || {}

  const address = [street1, street2, city, state, zipCode].filter(
    (fields) => fields
  )

  return address.join(', ')
}

const Option = ({
  header,
  id,
  address,
  checked,
  onChange,
  dataTestId
}: OptionProps) => {
  return (
    <Styled.AddressContainer>
      <Styled.SubHeader data-testid={dataTestId}>{header}</Styled.SubHeader>
      <Styled.AddressOption>
        <RadioButton
          id={id}
          checked={checked}
          onChange={onChange}
          data-testid={dataTestId}
          label={renderAddress(address)}
        />
      </Styled.AddressOption>
    </Styled.AddressContainer>
  )
}

type OptionProps = {
  header: string
  id: string
  address: ShippingFormData
  checked: boolean
  onChange: () => void
  dataTestId: string
}

export const SuggestedAddressModal = (): JSX.Element => {
  const getText = useGetText()
  const dispatch = useDispatch()
  const {
    suggestedAddress,
    shippingData: currentAddress,
    selectedAddress,
    showSuggestedAddressModal
  } = useShippingFormState()
  const [loading, setLoading] = useState(false)

  const onSelectAddress = async (address: ShippingFormData) =>
    await dispatch(saveSelectedAddressAction(address))
  const onShipToAddress = async () => {
    setLoading(true)
    await dispatch(handleShipToAddressAction())
  }

  if (!showSuggestedAddressModal) {
    return null
  }

  return (
    <Modal show={showSuggestedAddressModal}>
      <Styled.SuggestedAddressModal data-testid="suggested-address-modal">
        <h3>{getText('shipping_form.suggested_address_modal.title')}</h3>
        <div>
          <Option
            header={getText(
              'shipping_form.suggested_address_modal.suggested_address'
            )}
            id="suggested-address"
            address={suggestedAddress}
            checked={selectedAddress === suggestedAddress}
            onChange={() => onSelectAddress(suggestedAddress)}
            dataTestId="suggested-address"
          />
          <Option
            header={getText(
              'shipping_form.suggested_address_modal.original_address'
            )}
            id="original-address"
            address={currentAddress}
            checked={selectedAddress === currentAddress}
            onChange={() => onSelectAddress(currentAddress)}
            dataTestId="original-address"
          />
        </div>
        <Styled.ConfirmButton
          data-testid="ship-to-address-button"
          appearance="primary"
          loading={loading}
          onClick={() => onShipToAddress()}
        >
          {getText('shipping_form.suggested_address_modal.ship_to_address')}
        </Styled.ConfirmButton>
      </Styled.SuggestedAddressModal>
    </Modal>
  )
}
