import styled from 'styled-components'

export const UnsupportedPostalCodeContent = styled.div`
  margin: 0;
  text-align: left;
`

export const UnsupportedPostalCodeMessage = styled.div`
  max-width: 420px;
  margin: auto;

  p {
    font-size: 15px;
    line-height: 1.4;
    margin: 0 0 20px;
  }
`

export const UnsupportedPostalCodeSignature = styled.span`
  font-weight: bold;
`

export const UnsupportedPostalCodeCloseButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
