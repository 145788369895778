import {
  DispatchFunction,
  ShippingFormAction,
  ShippingFormData,
  ShippingFormDispatchHandler,
  ShippingFormState
} from '../types'
import { FormErrorCodes } from '../constants/shippingForm'
import { ShippingFormActionType } from '../constants/shippingFormAction'
import { saveFormErrorCodeAction } from '.'

export const associateAddressWithPrinterAction = (): ShippingFormAction => ({
  type: ShippingFormActionType.ASSOCIATE_ADDRESS_WITH_PRINTER
})

export const associateAddressWithPrinterSuccessAction = (
  data: ShippingFormData
): ShippingFormAction => ({
  type: ShippingFormActionType.ASSOCIATE_ADDRESS_WITH_PRINTER_SUCCESS,
  data
})

export const handleAssociateAddressWithPrinter =
  (shippingAddress: ShippingFormData): ShippingFormDispatchHandler =>
  async (dispatch: DispatchFunction, getState: () => ShippingFormState) => {
    try {
      await dispatch(associateAddressWithPrinterAction())
      const addressId = shippingAddress.id
      const { shippingService, cloudId, onSave } = getState()

      if (cloudId && addressId) {
        const { data } = await shippingService.associateAddressWithPrinter({
          id: addressId,
          cloud_id: cloudId
        })

        await dispatch(associateAddressWithPrinterSuccessAction(data))
      }

      onSave(null, { addressId: addressId })
    } catch (error) {
      await dispatch(saveFormErrorCodeAction(FormErrorCodes.ASSOCIATION_FAILED))
    }
  }
