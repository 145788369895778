import {
  DispatchFunction,
  ShippingFormAction,
  ShippingFormData,
  ShippingFormDispatchHandler,
  ShippingFormState
} from '../types'
import { ShippingFormActionType } from '../constants/shippingFormAction'
import { setAddressCountAction, setFetchErrorAction } from '.'

export const fetchShippingAddressesAction = (): ShippingFormAction => ({
  type: ShippingFormActionType.FETCH_SHIPPING_ADDRESSES
})

export const fetchShippingAddressesSuccessAction = (
  data: ShippingFormData[]
): ShippingFormAction => ({
  type: ShippingFormActionType.FETCH_SHIPPING_ADDRESSES_SUCCESS,
  data
})

export const fetchShippingAddressesFailedAction = (
  error: Record<string, unknown>
): ShippingFormAction => ({
  type: ShippingFormActionType.FETCH_SHIPPING_ADDRESSES_FAILED,
  error
})

export const handleFetchShippingAddressesAction =
  (): ShippingFormDispatchHandler =>
  async (dispatch: DispatchFunction, getState: () => ShippingFormState) => {
    try {
      await dispatch(fetchShippingAddressesAction())

      const shippingService = getState().shippingService

      const { data } = await shippingService.getAddresses()
      await dispatch(setAddressCountAction(data.length))
      await dispatch(fetchShippingAddressesSuccessAction(data))
    } catch (error) {
      await dispatch(fetchShippingAddressesFailedAction(error))
      await dispatch(setFetchErrorAction(handleFetchShippingAddressesAction()))
    }
  }
