import {
  DispatchFunction,
  SettingsData,
  ShippingFormAction,
  ShippingFormDispatchHandler,
  ShippingFormState
} from '../types'
import { ShippingFormActionType } from '../constants/shippingFormAction'
import { setFetchErrorAction } from '.'

export const fetchSettingsAction = (): ShippingFormAction => ({
  type: ShippingFormActionType.FETCH_SETTINGS_DATA
})

export const fetchSettingsSuccessAction = (
  data: SettingsData
): ShippingFormAction => ({
  type: ShippingFormActionType.FETCH_SETTINGS_DATA_SUCCESS,
  data
})

export const fetchSettingsFailedAction = (
  error: Record<string, unknown>
): ShippingFormAction => ({
  type: ShippingFormActionType.FETCH_SETTINGS_DATA_FAILED,
  error
})

export const handleFetchSettingsAction =
  (): ShippingFormDispatchHandler =>
  async (dispatch: DispatchFunction, getState: () => ShippingFormState) => {
    try {
      await dispatch(fetchSettingsAction())

      const shippingService = getState().shippingService
      const { data } = await shippingService.getSettings()
      await dispatch(fetchSettingsSuccessAction(data))
    } catch (error) {
      await dispatch(fetchSettingsFailedAction(error))
      await dispatch(setFetchErrorAction(handleFetchSettingsAction()))
    }
  }
